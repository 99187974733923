import React, { FunctionComponent, useContext } from "react";
import { Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { UserContext } from "../../contexts/UserContext";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPDATE_MY_EMAIL } from "../../requests/User";
import { Redirect } from "react-router";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

const ChangeEmail: FunctionComponent = () => {
  const { user, setUser } = useContext(UserContext);
  const { register, handleSubmit, errors } = useForm();
  const [callUpdate, { loading, data }] = useMutation(UPDATE_MY_EMAIL);
  const { addToast } = useToasts();

  const { t } = useTranslation();

  const onSubmit = (data: any) => {
    callUpdate({ variables: { email: data.email, password: data.password } });
  };

  if (data && data.changeMyEmail) {
    localStorage.setItem("auth_token", "");
    setUser(null);
    addToast("Votre adresse mail a bien été changée ! Vous devez maintenant vous reconnecter.", {
      appearance: "success",
    });
    return <Redirect to={"/connexion"} />;
  }

  return (
    <Card body>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Label>{t("adresse_e-mail")}:</Form.Label>
          <Form.Control type="email" readOnly={true} value={user?.email} />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("nouvelle_adresse_e-ma")}:</Form.Label>
          <Form.Control
            ref={register({ required: { value: true, message: "Ce champ est requis" } })}
            name="email"
            type="email"
            isInvalid={!!errors.email}
          />
          {errors.email && <Form.Control.Feedback type={"invalid"}>{errors.email.message}</Form.Control.Feedback>}
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("entrez_votre_mot_de_p")}:</Form.Label>
          <Form.Control
            ref={register({ required: { value: true, message: "Ce champ est requis" } })}
            name="password"
            type="password"
            isInvalid={!!errors.password}
          />
          {errors.password && <Form.Control.Feedback type={"invalid"}>{errors.password.message}</Form.Control.Feedback>}
        </Form.Group>
        <Button variant="primary" type="submit" disabled={loading}>
          {t("valider")}
        </Button>
      </Form>
    </Card>
  );
};

export default ChangeEmail;
