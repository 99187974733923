import React, { useEffect, useMemo, useState } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useQuery } from "@apollo/client";
import { GET_MY_EXPERIENCES_QUERY } from "../../requests/Parcours";
import AppLoader from "../../components/AppLoader";
import IndividualExp from "./IndividualExp";
import GlobalExp from "./GlobalExp";
import { useTranslation } from "react-i18next";

const MyExperiencesRenewal = () => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(GET_MY_EXPERIENCES_QUERY, { fetchPolicy: "network-only" });
  const [activeExperienceId, setActiveExperienceId] = useState<string | null>();

  const handleClickOnExperience = (experienceId: string) => {
    setActiveExperienceId(experienceId);
  };

  if (loading) return <AppLoader />;

  return (
    <Container>
      <h2>{t("mes_experiences")}</h2>
      <Card body>
        <Row>
          <Col md={4}>
            <ListGroup>
              <ListGroup.Item
                active={activeExperienceId === "all"}
                action
                onClick={() => handleClickOnExperience("all")}
              >
                {t("toutes_les_experience")}
              </ListGroup.Item>
              {data.getMyExperiences.map((v: any, index: number) => (
                <ListGroup.Item
                  active={activeExperienceId === v.id}
                  action
                  key={index}
                  onClick={() => handleClickOnExperience(v.id)}
                >
                  {v.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col md={8}>
            {!activeExperienceId && (
              <div className={"d-flex justify-content-center align-items-center"} style={{ height: "100%" }}>
                <p>{t("selectionnez_une_expe")}</p>
              </div>
            )}
            {activeExperienceId &&
            (activeExperienceId === "all" ? (
              <GlobalExp />
            ) : (
              <IndividualExp experienceId={activeExperienceId} onDeleteExp={() => setActiveExperienceId(null)} />
            ))}
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default MyExperiencesRenewal;


