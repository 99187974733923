import { createContext } from "react";

export interface User {
  id: string;
  email: string;
  role: "user" | "admin";
  tutorialComplete: boolean;
}

export const UserContext = createContext<{
  user: User | null | undefined;
  setUser: (user: User | null) => void;
}>({
  user: null,
  setUser: () => {},
});
