import React from "react";
import Loader from "../../assets/loader.svg";

const AppLoader = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img alt="Chargement..." src={Loader} height={"64px"} width={"64px"} />
    </div>
  );
};

export default AppLoader;
