import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { GET_GLOBAL_SUMMARY } from "../../requests/Parcours";
import AppLoader from "../../components/AppLoader";
import MiniCompetenceCard from "../../components/MiniCompetenceCard";
import AppFooter from "../../components/AppFooter";
import { useTranslation } from "react-i18next";

const CarteCompetenceGlobale = () => {
  const { t : dbt } = useTranslation("database");
  const { data: { getMyExperiences: experiences, getAllExperiencesSummary: summary } = {}, loading } = useQuery(
    GET_GLOBAL_SUMMARY
  );

  useEffect(() => {
    if (experiences && !loading) {
      setTimeout(() => window.print(), 1000);
    }
  }, [experiences, loading]);

  if (loading) return <AppLoader />;

  return (
    <Container className={"text-center d-flex flex-column"} style={{ minHeight: "100vh" }}>
      <main style={{ flex: 1 }}>
        <section>
          <h2 className={"py-1 mb-2 bg-primary text-light"}>Carte de compétences</h2>
        </section>
        <section>
          <h3 className={"py-1 mb-2 bg-secondary text-light"}>Expériences</h3>
          <div className={"text-left"}>
            <ul>
              {experiences.map((v: any) => (
                <li>
                  <strong>{v.name}</strong>
                  {v.activites && (
                    <ul>
                      {v.activites.map((w: any) => (
                        <li>{dbt(w.name)}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </section>
        <section>
          <h3 className={"py-1 mb-2 bg-secondary text-light"}>Compétences</h3>
          <Row>
            {summary
              .filter((v: any) => v.rating !== 0)
              .map((v: any) => (
                <Col key={v.id} md={6} className={"text-right d-flex flex-fill"}>
                  <MiniCompetenceCard
                    color={v.competence.color}
                    title={dbt(v.competence.name)}
                    description={dbt(v.competence.tooltips[v.rating - 1])}
                    rating={v.rating}
                  />
                </Col>
              ))}
          </Row>
        </section>
      </main>
      <AppFooter size={"sm"} />
    </Container>
  );
};

export default CarteCompetenceGlobale;
