import React, { FunctionComponent, useMemo } from "react";
import logoInterphaz from "../../assets/logo-interphaz.png";
import logoInterreg from "../../assets/logo-interreg.png";
import logoMdaRoubaix from "../../assets/logo-mda-roubaix.png";
import logoMdaTourcoing from "../../assets/logo-mda.png";
import logoMpa from "../../assets/logo-mpa.png";
import { Container, Card, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface IProps {
  size?: "sm" | "normal";
}

const AppFooter: FunctionComponent<IProps> = ({ size = "normal" }) => {
  const logoWidth = useMemo(() => {
    return size === "normal" ? 96 : 80;
  }, [size]);
  const { t } = useTranslation();
  return (
    <footer>
      <div style={{ textAlign: "center" }}>
        <a href={"https://www.interreg-fwvl.eu/"}>
          <Image className={"mx-2"} src={logoInterreg} width={logoWidth} />
        </a>
        <a href={"https://www.mda-tourcoing.fr/"}>
          <Image className={"mx-2"} src={logoMdaTourcoing} width={logoWidth} />
        </a>
        <a href={"https://www.mpa80.be/"}>
          <Image className={"mx-2"} src={logoMpa} width={logoWidth - 24} />
        </a>
        <a href={"http://www.mdaroubaix.org/"}>
          <Image className={"mx-2"} src={logoMdaRoubaix} width={logoWidth} />
        </a>
        <a href={"https://www.interphaz.org/"}>
          <Image className={"mx-2"} src={logoInterphaz} width={logoWidth} />
        </a>
      </div>
      <div className={"text-center mt-2"}>
        {t("referentiel_adapte_du")}
      </div>
    </footer>
  );
};

export default AppFooter;
