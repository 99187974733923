import React, { useContext, useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import CarteCritere from "../../../components/CarteCritere";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ReactTooltip from "react-tooltip";
import { Critere, GET_ALL_CRITERES_AND_DOMAINES } from "../../../requests/Critere";
import { useQuery } from "@apollo/client";
import { NewParcoursContext } from "../../../contexts/NewParcoursContext";
import AppLoader from "../../../components/AppLoader";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import RadarChart from "../../../components/RadarChart";
import { useTranslation } from "react-i18next";

const ChoixCriteres = () => {
  const { t } = useTranslation();
  const { t: dbt } = useTranslation("database");
  const { loading, error, data } = useQuery(GET_ALL_CRITERES_AND_DOMAINES);
  const context = useContext(NewParcoursContext);
  const [critereRating, setCritereRating] = useState<{ critereId: string; rating: number }[]>(context.ratedCriteres);
  const [domaine, setDomaine] = useState<string>(context.domaine || "");

  const handleNextStep = () => {
    context.setRatedCriteres(critereRating);
    context.setDomaine(domaine);
    context.goToNextStep();
  };

  useEffect(() => {
    if (data && critereRating.length <= 0) {
      setCritereRating(
        data.getCriteres.map((v: Critere) => ({
          critereId: v.id,
          rating: 0,
        }))
      );
    }
  }, [data, critereRating.length]);

  const handleChangeRating = (critereId: string, newRating: number) => {
    let updateRatings = [...critereRating];
    let toUpdate = critereRating.find((v) => v.critereId === critereId);
    if (toUpdate) toUpdate.rating = newRating;
    else updateRatings.push({ critereId: critereId, rating: newRating });
    setCritereRating(updateRatings);
  };

  const isValidForNextStep = useMemo(() => {
    const res = critereRating.every((v) => v.rating > 0);
    return res && domaine && domaine !== "";
  }, [critereRating, domaine]);

  const criteresChartData = useMemo(() => {
    if (!data) return {};
    return {
      labels: data.getCriteres.map((v: any) => dbt(v.name)),
      datasets: [
        {
          label: "Moi",
          backgroundColor: "#5156a1",
          borderColor: "#9594c9",
          data: data.getCriteres.map((v: any) => critereRating.find((w) => w.critereId === v.id)?.rating || 0),
          fill: false,
          radius: 4,
          pointBorderColor: "#9594c9",
          pointHoverRadius: 10,
        },
      ],
    };
  }, [data, critereRating]);

  if (loading) return <AppLoader />;
  if (error)
    return (
      <Alert variant={"danger"}>
        <strong>Erreur lors de la récupération des données</strong>
        <p>{error.message}</p>
      </Alert>
    );

  return (
    <>
      <Container>
        <Card>
          <Card.Header className={"bg-primary text-light"}>
            {t("etape_23__quel_est_")}
          </Card.Header>
          <Card.Body>
            <Alert variant={"info"}>
              {t("cette_etape_va_vous_p")}
            </Alert>
            <Row>
              <Col md={8}>
                <ListGroup variant={"flush"}>
                  {data.getCriteres.map((v: Critere, index: number) => (
                    <CarteCritere
                      key={index}
                      name={v.name}
                      description={v.description}
                      help={v.help}
                      tooltips={v.tooltips}
                      rating={critereRating.find((w) => w.critereId === v.id)?.rating || 0}
                      onChangeRating={(value) => handleChangeRating(v.id, value)}
                    />
                  ))}
                </ListGroup>
              </Col>
              <Col md={4}>
                <RadarChart
                  data={criteresChartData}
                  options={{
                    scale: {
                      ticks: {
                        beginAtZero: false,
                        min: 0,
                        max: 4,
                        stepSize: 1,
                        display: false,
                      },
                      pointLabels: {
                        fontSize: 14,
                        fontFamily: "'Source Sans Pro', sans-serif",
                      },
                    },
                    legend: {
                      display: false,
                    },
                    tooltips: {
                      enabled: false,
                    },
                  }}
                />
              </Col>
            </Row>
            <hr />
            <div>
              <p>
                <strong>{t("Sélectionnez le domaine de votre expérience :")}</strong>
              </p>
              <Form.Control as="select" value={domaine} onChange={(e) => setDomaine(e.currentTarget.value)}>
                <option value={""} hidden>
                  ---
                </option>
                {data.getDomaines.map((d: any) => (
                  <option key={d.id} value={d.id}>
                    {dbt(d.name)}
                  </option>
                ))}
              </Form.Control>
            </div>
            <div className={"mt-2 text-center"}>
              <Button variant={"secondary"} onClick={context.goToPrevStep}>
                {t("retour")}
              </Button>
              &nbsp;
              <Button variant={"primary"} disabled={!isValidForNextStep} onClick={handleNextStep}>
                {t("etape_suivante")}
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Container>
      <ReactTooltip />
    </>
  );
};

export default ChoixCriteres;
