import React, { useEffect, useMemo } from "react";
import { Container, Col, Row, Alert, Image } from "react-bootstrap";
import { useParams } from "react-router";
import { GET_EXPERIENCE_BY_ID_FOR_CARTE_COMPETENCE } from "../../requests/Parcours";
import { useQuery } from "@apollo/client";
import AppLoader from "../../components/AppLoader";
import RadarChart from "../../components/RadarChart";
import MiniCompetenceCard from "../../components/MiniCompetenceCard";
import AppFooter from "../../components/AppFooter";
import { useTranslation } from "react-i18next";

const CarteCompetence = () => {
  const { t : dbt } = useTranslation("database");
  const { experienceId } = useParams<any>();
  const { data: { getExperienceById: experience } = {}, loading, error } = useQuery(
    GET_EXPERIENCE_BY_ID_FOR_CARTE_COMPETENCE,
    {
      variables: { experienceId },
    }
  );

  const chartData = useMemo(() => {
    if (!experience) return {};
    return {
      labels: experience.criteres.map((v: any) => dbt(v.critere.name)),
      datasets: [
        {
          label: "Moi",
          backgroundColor: "#5156a1",
          borderColor: "#9594c9",
          data: experience.criteres.map((v: any) => v.rating),
          fill: false,
          radius: 4,
          pointBorderColor: "#9594c9",
          pointHoverRadius: 10,
        },
      ],
    };
  }, [experience]);

  const mergedCompetences = useMemo(() => {
    if (!experience) return [];
    const result: any = {};

    for (let a of experience.activites) {
      for (let c of a.competences) {
        if (result[c.competence.id]) {
          const highestRating = Math.max(result[c.competence.id].rating, c.rating);
          result[c.competence.id].rating = highestRating;
          result[c.competence.id].tooltip = c.competence.tooltips[highestRating - 1];
        } else {
          result[c.competence.id] = {
            rating: c.rating,
            tooltip: dbt(c.competence.tooltips[c.rating - 1]),
            name: dbt(c.competence.name),
            color: c.competence.color,
            id: c.competence.id,
          };
        }
      }
    }

    return Object.values(result);
  }, [experience]);

  useEffect(() => {
    if (experience && !loading) {
      setTimeout(() => window.print(), 1000);
    }
  }, [experience, loading]);

  if (loading || !experience) return <AppLoader />;
  if (error) return <Alert variant={"danger"}>Erreur lors de la requête</Alert>;

  return (
    <Container className={"text-center d-flex flex-column"} style={{ minHeight: "100vh" }}>
      <main style={{ flex: 1 }}>
        <section>
          <h2 className={"py-1 mb-2 bg-primary text-light"}>Carte de compétences</h2>
          <h3 className={"py-1 mb-2 text-dark"}>{experience.name}</h3>
          <Row>
            <Col md={8} className={"text-left"}>
              <dl>
                {experience.criteres.map((v: any) => (
                  <div key={v.critereId}>
                    <dt>
                      {dbt(v.critere.name)}: {dbt(v.critere.help)}
                    </dt>
                    <dd>{dbt(v.critere.tooltips[Math.max(v.rating - 1, 0)])}</dd>
                  </div>
                ))}
              </dl>
            </Col>
            <Col md={4} className={"align-self-center"}>
              <RadarChart
                data={chartData}
                options={{
                  animation: {
                    duration: 0,
                  },
                  scale: {
                    ticks: {
                      beginAtZero: false,
                      min: 0,
                      max: 4,
                      stepSize: 1,
                      display: false,
                    },
                    pointLabels: {
                      fontSize: 14,
                      fontFamily: "'Source Sans Pro', sans-serif",
                    },
                  },
                  legend: {
                    display: false,
                  },
                  tooltips: {
                    enabled: false,
                  },
                }}
              />
            </Col>
          </Row>
        </section>
        <section>
          <h3 className={"py-1 mb-2 bg-secondary text-light"}>Mes activités</h3>
          <ul>
            {experience.activites.map((v: any, index: number) => (
              <li key={index} className={"text-left"}>
                {dbt(v.name)}
              </li>
            ))}
          </ul>
        </section>
        <section>
          <h3 className={"py-1 mb-2 bg-secondary text-light"}>Compétences</h3>
          <Row>
            {mergedCompetences.map((v: any, index: number) => {
              if (v.rating <= 0) return null;
              return (
                <Col key={v.id} md={6} className={"text-right d-flex flex-fill"}>
                  <MiniCompetenceCard color={v.color} title={dbt(v.name)} description={dbt(v.tooltip)} rating={v.rating} />
                </Col>
              );
            })}
          </Row>
        </section>
      </main>
      <AppFooter size={"sm"} />
    </Container>
  );
};

export default CarteCompetence;
