import React, { useMemo } from "react";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";

import locales from "../../config/locales";

const AppLanguageMenu = () => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const dropdownFlag = useMemo(() => {
    const l = locales.find((v) => v.lng === i18n.language || v.aliases.find((w) => w === i18n.language));
    return l && l.flagKey ? <Flag code={l.flagKey} height={24} width={24} /> : "Language";
  }, [i18n.language]);

  return (
    <>
      <NavDropdown title={dropdownFlag} id={"language-dropdown"}>
        {locales
          .filter((v) => v.lng !== i18n.language && v.aliases.find((w) => w !== i18n.language))
          .map((v) => (
            <NavDropdown.Item key={v.lng} onClick={() => handleChangeLanguage(v.lng)}>
              {v.flagKey && (
                <>
                  <Flag code={v.flagKey} height={20} width={20} />
                  &nbsp;
                </>
              )}
              {v.name}
            </NavDropdown.Item>
          ))}
      </NavDropdown>
    </>
  );
};

export default AppLanguageMenu;
