import { ChartOptions } from "chart.js";

const options: ChartOptions = {
  legend: { display: true, position: "right" },
  scale: {
    ticks: {
      display: false,
      min: -1,
      max: 4,
    },
  },
  hover: {
    animationDuration: 0,
  },
};

export default options;
