import { gql } from "@apollo/client";

export type Competence = {
  id: string;
  name: string;
  color?: string;
  tooltips?: string[];
};

export type GetAllCompetencesResponse = {
  getCompetences: Competence[];
}

export const GET_ALL_COMPETENCES = gql`
  {
    getCompetences {
      id
      name
      color
      tooltips
    }
  }
`;

export const GET_ALL_COMPETENCES_FOR_ADMIN = gql`
  {
    getCompetences {
      id
      name
      color
    }
  }
`;

export const GET_COMPETENCE_FOR_ADMIN = gql`
  query($id: ID!) {
    getCompetenceById(id: $id) {
      id
      name
      color
      tooltips
    }
  }
`;

export const UPDATE_COMPETENCE_FOR_ADMIN = gql`
  mutation($competenceId: ID!, $updateData: CreateCompetenceArgs!) {
    editCompetence(competenceId: $competenceId, updateData: $updateData) {
      id
      name
      color
      tooltips
    }
  }
`;

export const CREATE_COMPETENCE_FOR_ADMIN = gql`
  mutation($creationData: CreateCompetenceArgs!) {
    newCompetence(creationData: $creationData) {
      id
      name
      color
      tooltips
    }
  }
`;

export const DELETE_COMPETENCE_FOR_ADMIN = gql`
  mutation($id: ID!) {
    deleteCompetence(id: $id)
  }
`;
