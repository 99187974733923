import React, { FunctionComponent, useContext } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useMutation } from "@apollo/client";
import { DELETE_MY_ACCOUNT } from "../../requests/User";
import { Redirect } from "react-router";
import { UserContext } from "../../contexts/UserContext";
import Alert from "react-bootstrap/Alert";
import { useToasts } from "react-toast-notifications";

import { useTranslation } from "react-i18next";

const DeleteAccount: FunctionComponent = () => {
  const { setUser } = useContext(UserContext);
  const [callDelete, { loading, data }] = useMutation(DELETE_MY_ACCOUNT);
  const { addToast } = useToasts();

  const { t } = useTranslation();

  const handleConfirmDeletion = () => {
    callDelete();
  };

  if (data && data.deleteMyAccount) {
    localStorage.setItem("auth_token", "");
    setUser(null);
    addToast("Votre compte a bien été supprimé !", { appearance: "info" });
    return <Redirect to={"/connexion"} />;
  }

  return (
    <div>
      <Card>
        <Card.Header className={"bg-danger text-light"}>{t("suppression_de_compte")}</Card.Header>
        <Card.Body>
          {data && !data.deleteMyAccount && (
            <Alert variant={"danger"}>Une erreur est survenue lors de la suppression du compte.</Alert>
          )}
          {t("voulez-vous_vraiment_")}
          <div className={"mt-3 text-right"}>
            <Button variant={"danger"} disabled={loading} onClick={handleConfirmDeletion}>
              {t("oui_supprimer_mon_co")}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DeleteAccount;
