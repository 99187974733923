import React, { ChangeEvent, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_CRITERE_FOR_ADMIN,
  GET_ALL_CRITERES_FOR_ADMIN,
  GET_CRITERE_FOR_ADMIN,
  UPDATE_CRITERE_FOR_ADMIN,
} from "../../../requests/Critere";
import { useParams } from "react-router";
import Card from "react-bootstrap/Card";
import AppLoader from "../../../components/AppLoader";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { useHistory } from "react-router-dom";

const EditCritere = () => {
  const history = useHistory();
  const { id: critereId } = useParams<any>();
  const [backendError, setBackendError] = useState<any>(null);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [help, setHelp] = useState<string>("");
  const [tooltips, setTooltips] = useState<string[]>(["", "", "", ""]);
  const [fetchCritere, { loading, error }] = useLazyQuery(GET_CRITERE_FOR_ADMIN, {
    onCompleted: (data) => {
      const critere = data.getCritereById;
      setTitle(critere.name);
      setDescription(critere.description);
      setHelp(critere.help);
      setTooltips(critere.tooltips);
    },
  });

  const [updateCritere] = useMutation(UPDATE_CRITERE_FOR_ADMIN, {
    refetchQueries: [{ query: GET_ALL_CRITERES_FOR_ADMIN }],
    onCompleted: () => history.push("/admin/criteres"),
    onError: (error1) => setBackendError(error1.message),
  });
  const [createCritere] = useMutation(CREATE_CRITERE_FOR_ADMIN, {
    refetchQueries: [{ query: GET_ALL_CRITERES_FOR_ADMIN }],
    onCompleted: () => history.push("/admin/criteres"),
    onError: (error1) => setBackendError(error1.message),
  });

  useEffect(() => {
    fetchCritere({ variables: { id: critereId } });
  }, [fetchCritere, critereId]);

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.currentTarget.value);
  };

  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.currentTarget.value);
  };

  const handleChangeHelp = (e: ChangeEvent<HTMLInputElement>) => {
    setHelp(e.currentTarget.value);
  };

  const handleEditTooltip = (index: number, e: ChangeEvent<HTMLInputElement>) => {
    const update = [...tooltips];
    update[index] = e.currentTarget.value;
    setTooltips(update);
  };

  const handleFormSubmit = () => {
    // TODO
    if (critereId) {
      updateCritere({
        variables: {
          critereId,
          updateData: {
            name: title,
            description,
            help,
            tooltips,
          },
        },
      });
    } else {
      createCritere({
        variables: {
          creationData: {
            name: title,
            description,
            help,
            tooltips,
          },
        },
      });
    }
  };

  return (
    <Card>
      <Card.Header>
        <strong>{critereId ? "Edition d'un critère" : "Créer un critère"}</strong>
      </Card.Header>
      {critereId && loading ? (
        <AppLoader />
      ) : (
        <Card.Body>
          {backendError && (
            <Alert variant={"danger"}>
              <strong>Erreur lors de la mise à jour</strong>
              <div>{backendError}</div>
            </Alert>
          )}
          <Form>
            <Form.Group>
              <Form.Label className={"form-required"}>Nom du critère</Form.Label>
              <Form.Control type="text" required onChange={handleChangeTitle} value={title} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control type="text" onChange={handleChangeDescription} value={description} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Message d'aide</Form.Label>
              <Form.Control type="text" onChange={handleChangeHelp} value={help} />
            </Form.Group>
            {[1, 2, 3, 4].map((v, index) => (
              <Form.Group>
                <Form.Label>Description du palier {v}</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleEditTooltip(index, e)}
                  value={tooltips[index] || ""}
                />
              </Form.Group>
            ))}
          </Form>
          <Button onClick={handleFormSubmit}>Valider</Button>
        </Card.Body>
      )}
    </Card>
  );
};

export default EditCritere;
