import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import AppModal from "../../components/AppModal";
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_EXPERIENCE,
  GET_EXPERIENCE_BY_ID_FOR_CARTE_COMPETENCE,
  GET_MY_EXPERIENCES_QUERY,
} from "../../requests/Parcours";
import { Competence, GET_ALL_COMPETENCES } from "../../requests/Competence";
import AppLoader from "../../components/AppLoader";
import PolarAreaChart from "../../components/PolarAreaChart";
import chartConfig from "../../config/chartBilan";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

interface IProps {
  experienceId: string;
  onDeleteExp?: () => void;
}

const IndividualExp: FunctionComponent<IProps> = ({ experienceId, onDeleteExp }) => {
  const { t } = useTranslation();
  const { t: dbt } = useTranslation("database");
  const [showDelModal, setShowDelModal] = useState(false);
  const { data: { getExperienceById: experience } = {}, loading: loadingExp } = useQuery(
    GET_EXPERIENCE_BY_ID_FOR_CARTE_COMPETENCE,
    {
      variables: { experienceId },
    }
  );
  const { data: competenceList, loading: competencesLoading } = useQuery(GET_ALL_COMPETENCES);

  const [callDeleteExp, { data: deleteExpData, loading: deleteExpLoading }] = useMutation(DELETE_EXPERIENCE, {
    refetchQueries: [{ query: GET_MY_EXPERIENCES_QUERY }],
  });

  const handleConfirmDelExp = () => {
    callDeleteExp({
      variables: {
        experienceId: experienceId,
      },
    });
  };

  useEffect(() => {
    if (deleteExpData?.deleteExperience) {
      setShowDelModal(false);
      onDeleteExp?.call(null);
    }
  }, [deleteExpData]);

  const chartData = useMemo(() => {
    if (!experience || !competenceList) return [];
    const res: any = {};
    for (let a of experience.activites) {
      for (let b of a.competences) {
        if (res[b.competence.id] === undefined) {
          const compDbData = competenceList.getCompetences.find((v: Competence) => v.id === b.competence.id);
          res[b.competence.id] = {
            name: compDbData?.name || "Nom invalide",
            color: compDbData?.color || undefined,
            rating: b.rating,
          };
        } else res[b.competence.id].rating = Math.max(res[b.competence.id].rating, b.rating);
      }
    }

    return Object.values(res);
  }, [competenceList, experience]);

  const data2 = useMemo(
    () => ({
      datasets: [
        {
          data: chartData.map((v: any) => v.rating),
          backgroundColor: chartData.map((v: any) => v.color),
        },
      ],
      labels: chartData.map((v: any) => dbt(v.name)),
    }),
    [chartData]
  );

  if (competencesLoading || loadingExp || !competenceList || !experience) {
    return (
      <div className={"d-flex justify-content-center align-items-center"} style={{ height: "100%" }}>
        <AppLoader />
      </div>
    );
  }

  return (
    <>
      <div>
        <div className={"pr-1"}>
          <PolarAreaChart
            data={data2}
            options={{
              ...chartConfig,
              ...{ legend: { position: "left" } },
            }}
          />
        </div>
        <Link to={`/carte/experience/${experience.id}`} target={"_blank"}>
          <Button className={"ml-2"} variant={"primary"}>
            {t("telecharger")} / {t("imprimer")}
          </Button>
        </Link>
        <Link to={`/parcours/${experience.id}`}>
          <Button className={"ml-2"} variant="light">
            {t("Modifier")}
          </Button>
        </Link>
        <Button className={"ml-2"} variant="light" onClick={() => setShowDelModal(true)}>
          {t("Supprimer")}
        </Button>
      </div>
      <AppModal
        show={showDelModal}
        cancelText={"Non"}
        confirmText={"Oui, je veux supprimer cette expérience"}
        onConfirm={handleConfirmDelExp}
        onClose={() => setShowDelModal(false)}
        disableConfirm={deleteExpLoading}
      >
        Voulez-vous vraiment supprimer cette expérience ?
      </AppModal>
    </>
  );
};

export default IndividualExp;
;
