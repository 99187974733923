import React, { useState } from "react";
import { Competence, DELETE_COMPETENCE_FOR_ADMIN, GET_ALL_COMPETENCES_FOR_ADMIN } from "../../../requests/Competence";
import { useMutation, useQuery } from "@apollo/client";
import AppLoader from "../../../components/AppLoader";
import { Helmet } from "react-helmet";
import Card from "react-bootstrap/esm/Card";
import Table from "react-bootstrap/esm/Table";
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import AppModal from "../../../components/AppModal";

const ColorBox = ({ color }: { color: string | undefined }) => {
  return (
    <div
      style={{
        height: "1.5em",
        width: "4em",
        border: "1px solid #666",
        backgroundColor: color,
        display: "inline-block",
        verticalAlign: "middle",
      }}
    />
  );
};

const ListCompetences = () => {
  const { data, loading, error } = useQuery(GET_ALL_COMPETENCES_FOR_ADMIN);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [competenceForDeletion, setCompetenceForDeletion] = useState<Competence | null>(null);
  const [deleteCompetence] = useMutation(DELETE_COMPETENCE_FOR_ADMIN, {
    refetchQueries: [{ query: GET_ALL_COMPETENCES_FOR_ADMIN }],
  });

  const handleClickDelete = (competenceId: string) => {
    setCompetenceForDeletion(data?.getCompetences.find((v: Competence) => v.id === competenceId));
    setShowDeleteModal(true);
  };

  const handleCloseDeletionModal = () => {
    setCompetenceForDeletion(null);
    setShowDeleteModal(false);
  };

  const handleConfirmDeletionModal = () => {
    if (competenceForDeletion) {
      deleteCompetence({
        variables: {
          id: competenceForDeletion.id,
        },
      });
    }
    setShowDeleteModal(false);
  };

  if (loading) return <AppLoader />;

  return (
    <>
      <Helmet title={"Gestion des compétences"} />
      <Card body>
        <div className={"mb-2 float-right"}>
          <Link to={`/admin/competences/new`}>
            <Button variant={"primary"} size={"sm"}>
              Ajouter une compétence
            </Button>
          </Link>
        </div>
        <Table bordered size={"sm"} striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th className={"text-center"}>Couleur</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.getCompetences.map((v: Competence) => (
                <tr>
                  <td>{v.name}</td>
                  <td className={"text-center"}>
                    <ColorBox color={v.color} />
                  </td>
                  <td>
                    <Link to={`/admin/competences/${v.id}/edit`}>
                      <Button variant={"link"}>Editer</Button>
                    </Link>
                    <Button onClick={() => handleClickDelete(v.id)} variant={"link"}>
                      Supprimer
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card>
      <AppModal
        show={showDeleteModal}
        title="Confirmer la suppression"
        onClose={handleCloseDeletionModal}
        onConfirm={handleConfirmDeletionModal}
      >
        Supprimer la compétence {competenceForDeletion?.name && <strong>{competenceForDeletion.name}</strong>} ?
      </AppModal>
    </>
  );
};

export default ListCompetences;
