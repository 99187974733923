import React, { useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useMutation } from "@apollo/client";
import { SIGNUP_MUTATION } from "../../requests/User";
import { useHistory } from "react-router";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

const Register = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");
  const { addToast } = useToasts();

  const [sendSignup, { loading }] = useMutation(SIGNUP_MUTATION, {
    onCompleted: (data) => {
      if (data.signup) {
        addToast("Inscription réussie. Vous pouvez désormais vous connecter.", { appearance: "success" });
        history.push("/connexion");
      } else addToast("Inscription échouée. Peut-être possédez-vous déjà un compte ?", { appearance: "error" });
    },
  });

  const onSubmit = (data: { email: string; password: string }) => {
    sendSignup({ variables: { email: data.email, password: data.password } });
  };

  return (
    <Container>
      <h2>Inscription</h2>
      <Card body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>{t("adresse_e-mail")}:</Form.Label>
            <Form.Control
              ref={register({
                required: { value: true, message: "Ce champ est requis" },
              })}
              name="email"
              type={"email"}
              isInvalid={!!errors.email}
            />
            {errors.email && <Form.Control.Feedback type={"invalid"}>{errors.email.message}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group>
            <Form.Label>Mot de passe:</Form.Label>
            <Form.Control
              ref={register({
                required: { value: true, message: "Ce champ est requis" },
                minLength: { value: 4, message: "Le mot de passe doit contenir au moins 4 caractères" },
              })}
              name="password"
              type={"password"}
              isInvalid={!!errors.password}
            />
            {errors.password && (
              <Form.Control.Feedback type={"invalid"}>{errors.password.message}</Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirmez le mot de passe:</Form.Label>
            <Form.Control
              ref={register({
                required: { value: true, message: "Ce champ est requis" },
                validate: (value) => value === password.current || "Les mots de passe sont différents",
              })}
              name={"passwordConfirm"}
              type={"password"}
              isInvalid={!!errors.passwordConfirm}
            />
            {errors.passwordConfirm && (
              <Form.Control.Feedback type={"invalid"}>{errors.passwordConfirm.message}</Form.Control.Feedback>
            )}
          </Form.Group>
          <Button type={"submit"} disabled={loading}>
            {t("valider")} l'inscription
          </Button>
        </Form>
      </Card>
      <Card.Footer className={"mt-2"}>
        <span>Vous possédez déjà un compte ? </span>
        <Link to="/connexion">
          <strong>Cliquez ici</strong>
        </Link>{" "}
        <span>pour vous connecter</span>
      </Card.Footer>
    </Container>
  );
};

export default Register;
