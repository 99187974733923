import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NewParcoursContext } from "../../../contexts/NewParcoursContext";
import Alert from "react-bootstrap/esm/Alert";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";

const ChoixTheme = () => {
  const { t } = useTranslation();
  const context = useContext(NewParcoursContext);
  const [themeName, setThemeName] = useState<string>("");

  useEffect(() => {
    setThemeName(context.themeName);
  }, [context.themeName]);

  const handleChangeThemeName = (e: ChangeEvent<HTMLInputElement>) => {
    setThemeName(e.currentTarget.value);
  };

  const handleNextStep = () => {
    context.setThemeName(themeName);
    context.goToNextStep();
  };

  const isValidForNextStep = useMemo(() => {
    return themeName !== "";
  }, [themeName]);

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Header className={"bg-primary text-light"}>
              {t("etape_13__renseigne")}
            </Card.Header>
            <Card.Body>
              <Form.Group>
                <Alert variant={"info"}>
                  <Alert.Heading>{t("avant_de_commencer")}</Alert.Heading>
                  <div>
                    {t("nous_vous_conseillons")}
                  </div>
                </Alert>
                <Form.Control
                  type="text"
                  required={true}
                  value={themeName}
                  onChange={handleChangeThemeName}
                  placeholder={t("tresorier_de_lassoci")}
                />
              </Form.Group>
              <div className={"text-center"}>
                <Button type={"submit"} disabled={!isValidForNextStep} onClick={handleNextStep}>
                  {t("etape_suivante")}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ChoixTheme;
