import React, { FunctionComponent, useContext, useRef } from "react";
import { Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPDATE_MY_PASSWORD } from "../../requests/User";
import { Redirect } from "react-router";
import { UserContext } from "../../contexts/UserContext";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

const ChangePassword: FunctionComponent = () => {
  const { setUser } = useContext(UserContext);
  const { register, handleSubmit, errors, watch } = useForm();
  const [callUpdate, { loading, data }] = useMutation(UPDATE_MY_PASSWORD);
  const newPassword = useRef({});
  newPassword.current = watch("newPassword", "");
  const { addToast } = useToasts();

  const { t } = useTranslation();

  const onSubmit = (data: any) => {
    callUpdate({ variables: { password: data.newPassword } });
  };

  if (data) {
    localStorage.setItem("auth_token", "");
    setUser(null);
    addToast("Votre mot de passe a bien été changé ! Vous devez maintenant vous reconnecter.", {
      appearance: "success",
    });
    return <Redirect to={"/connexion"} />;
  }

  return (
    <Card body>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Label>{t("nouveau_mot_de_passe")}:</Form.Label>
          <Form.Control
            ref={register({
              required: { value: true, message: "Ce champ est requis" },
              minLength: { value: 4, message: "Le mot de passe doit contenir au moins 4 caractères" },
            })}
            name="newPassword"
            type="password"
            isInvalid={!!errors.newPassword}
          />
          {errors.newPassword && (
            <Form.Control.Feedback type={"invalid"}>{errors.newPassword.message}</Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("confirmez_le_nouveau_")}:</Form.Label>
          <Form.Control
            ref={register({
              required: { value: true, message: "Ce champ est requis" },
              validate: (value) => value === newPassword.current || "Les mots de passe sont différents",
            })}
            name="newPasswordConfirm"
            type="password"
            isInvalid={!!errors.newPasswordConfirm}
          />
          {errors.newPasswordConfirm && (
            <Form.Control.Feedback type={"invalid"}>{errors.newPasswordConfirm?.message}</Form.Control.Feedback>
          )}
        </Form.Group>
        <Button variant="primary" type="submit" disabled={loading}>
          {t("valider")}
        </Button>
      </Form>
    </Card>
  );
};

export default ChangePassword;
