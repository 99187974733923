import { gql } from "@apollo/client";

export const APPEND_PARCOURS_MUTATION = gql`
  mutation addToParcours($updateData: AddExperienceArgs!) {
    appendParcours(updateData: $updateData) {
      id
      name
    }
  }
`;

export const UPDATE_PARCOURS_EXP_MUTATION = gql`
  mutation editParcoursExp($experienceId: ID!, $updateData: AddExperienceArgs!) {
    updateParcoursExperience(experienceId: $experienceId, updateData: $updateData) {
      id
      name
    }
  }
`;

export const GET_MY_EXPERIENCES_QUERY = gql`
  query {
    getMyExperiences {
      id
      name
    }
  }
`;

export const GET_MY_EXPERIENCES_COMPLETE_QUERY = gql`
  query {
    getMyExperiences {
      id
      name
      criteres {
        critere {
          name
        }
        rating
      }
      activites {
        name
        competences {
          competence {
            id
            name
          }
          rating
        }
      }
    }
  }
`;

export const GET_EXPERIENCE_BY_ID = gql`
  query($experienceId: ID!) {
    getExperienceById(experienceId: $experienceId) {
      id
      name
      domaine
      criteres {
        critereId
        rating
      }
      activites {
        name
        competences {
          competenceId
          rating
        }
      }
    }
  }
`;

export const GET_EXPERIENCE_BY_ID_FOR_CARTE_COMPETENCE = gql`
  query($experienceId: ID!) {
    getExperienceById(experienceId: $experienceId) {
      id
      name
      criteres {
        critere {
          name
          help
          tooltips
        }
        rating
      }
      activites {
        name
        competences {
          competence {
            id
            name
            color
            tooltips
          }
          rating
        }
      }
    }
  }
`;

export const DELETE_EXPERIENCE = gql`
  mutation($experienceId: ID!) {
    deleteExperience(experienceId: $experienceId)
  }
`;

export const GET_ALL_EXP_SUMMARY = gql`
  query {
    getAllExperiencesSummary {
      competenceId
      rating
    }
  }
`;

export const GET_GLOBAL_SUMMARY = gql`
  query {
    getAllExperiencesSummary {
      competence {
        name
        color
        tooltips
      }
      rating
    }
    getMyExperiences {
      name
      activites {
        name
      }
    }
  }
`;
