import React from "react";
import { useQuery } from "@apollo/client";
import AppLoader from "../../../components/AppLoader";
import { Helmet } from "react-helmet";
import Card from "react-bootstrap/Card";
import { GET_ALL_USERS_FOR_ADMIN } from "../../../requests/User";
import Table from "react-bootstrap/Table";
import { User } from "../../../contexts/UserContext";

const ListUsers = () => {
  const { data, loading, error } = useQuery(GET_ALL_USERS_FOR_ADMIN);

  if (loading) return <AppLoader />;

  return (
    <>
      <Helmet title={"Gestion des utilisateurs"} />
      <Card body>
        <Table bordered size={"sm"} striped>
          <thead>
            <tr>
              <th>Adresse e-mail</th>
              <th>Rôle</th>
              {/*<th>Actions</th>*/}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.getUsers.map((v: User) => (
                <tr>
                  <td>{v.email}</td>
                  <td>{v.role === "admin" ? "Administrateur" : "Utilisateur"}</td>
                  {/*<td>
                    <Link to={`/admin/users/${v.id}/edit`}>
                      <Button variant={"link"}>Editer</Button>
                    </Link>
                  </td>*/}
                </tr>
              ))}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default ListUsers;
