import { gql } from "@apollo/client";

export const SIGNUP_MUTATION = gql`
  mutation signup($email: String!, $password: String!) {
    signup(email: $email, password: $password)
  }
`;

export const LOGIN_QUERY = gql`
  query login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        email
        role
        tutorialComplete
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  query {
    refreshToken {
      token
      user {
        email
        role
        tutorialComplete
      }
    }
  }
`;

export const GET_ALL_USERS_FOR_ADMIN = gql`
  query {
    getUsers {
      id
      email
      role
    }
  }
`;

export const UPDATE_MY_EMAIL = gql`
  mutation($email: String!, $password: String!) {
    changeMyEmail(email: $email, password: $password)
  }
`;

export const UPDATE_MY_PASSWORD = gql`
  mutation($password: String!) {
    changeMyPassword(newPassword: $password)
  }
`;

export const DELETE_MY_ACCOUNT = gql`
  mutation {
    deleteMyAccount
  }
`;

export const UPDATE_TUTORIAL_STATE = gql`
  mutation {
    completedTutorial
  }
`;
