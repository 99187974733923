import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import AppLoader from "../../../components/AppLoader";
import { Helmet } from "react-helmet";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { GET_ALL_CRITERES_FOR_ADMIN, Critere, DELETE_CRITERE_FOR_ADMIN } from "../../../requests/Critere";
import { Link } from "react-router-dom";
import AppModal from "../../../components/AppModal";

const ListCriteres = () => {
  const { data, loading, error } = useQuery(GET_ALL_CRITERES_FOR_ADMIN);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [critereForDeletion, setCritereForDeletion] = useState<Critere | null>(null);
  const [deleteCritere] = useMutation(DELETE_CRITERE_FOR_ADMIN, {
    refetchQueries: [{ query: GET_ALL_CRITERES_FOR_ADMIN }]
  });

  const handleClickDelete = (critereId: string) => {
    setCritereForDeletion(data?.getCriteres.find((v: Critere) => v.id === critereId));
    setShowDeleteModal(true);
  };

  const handleCloseDeletionModal = () => {
    setCritereForDeletion(null);
    setShowDeleteModal(false);
  };

  const handleConfirmDeletionModal = () => {
    if (critereForDeletion) {
      deleteCritere({
        variables: {
          id: critereForDeletion.id,
        },
      });
    }
    setShowDeleteModal(false);
  };

  if (loading) return <AppLoader />;

  return (
    <>
      <Helmet title={"Gestion des critères"} />
      <Card body>
        <div className={"mb-2 float-right"}>
          <Link to={"/admin/criteres/new"}>
            <Button variant={"primary"} size={"sm"}>
              Ajouter un critère
            </Button>
          </Link>
        </div>
        <Table bordered size={"sm"} striped>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.getCriteres.map((v: Critere) => (
                <tr>
                  <td>{v.name}</td>
                  <td>
                    <Link to={`/admin/criteres/${v.id}/edit`}>
                      <Button variant={"link"}>Editer</Button>
                    </Link>
                    <Button onClick={() => handleClickDelete(v.id)} variant={"link"}>
                      Supprimer
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card>
      <AppModal
        show={showDeleteModal}
        title="Confirmer la suppression"
        onClose={handleCloseDeletionModal}
        onConfirm={handleConfirmDeletionModal}
      >
        Supprimer le critère {critereForDeletion?.name && <strong>{critereForDeletion.name}</strong>} ?
      </AppModal>
    </>
  );
};

export default ListCriteres;
