import React from "react";
import { Activite } from "../requests/Activite";

export enum EParcoursStep {
  THEME,
  CRITERES,
  COMPETENCES,
  BILAN,
}

interface ParcoursContextType {
  themeName: string;
  ratedCriteres: { critereId: string; rating: number }[];
  activites: Activite[];
  domaine: string | null;

  setThemeName: (theme: string) => void;
  setRatedCriteres: (criteres: { critereId: string; rating: number }[]) => void;
  setActivites: (activites: Activite[]) => void;
  setDomaine: (domaineId: string) => void;

  goToPrevStep: () => void;
  goToNextStep: () => void;

  resetNewParcours: () => void;
}

export const NewParcoursContext = React.createContext<ParcoursContextType>({
  themeName: "",
  ratedCriteres: [],
  activites: [],
  domaine: null,

  setThemeName: (theme) => {},
  setRatedCriteres: (criteres) => {},
  setActivites: (activites) => {},
  setDomaine: (domaineId) => {},

  goToPrevStep: () => {},
  goToNextStep: () => {},

  resetNewParcours: () => {},
});
