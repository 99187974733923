import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import { Competence, GET_ALL_COMPETENCES } from "../../requests/Competence";
import PolarAreaChart from "../PolarAreaChart";
import chartConfig from "../../config/chart.json";
import Rating from "../Rating";
import { useQuery } from "@apollo/client";
import AppLoader from "../AppLoader";
import Alert from "react-bootstrap/Alert";
import ReactTooltip from "react-tooltip";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/all";
import { useTranslation } from "react-i18next";

type CardProps = {
  localId: string;
  onChange?: (nomActivite: string, ratings: { competenceId: string; rating: number }[]) => void;
  value: any;
  activeHint?: boolean;
  onDelete?: () => void;
};

const CarteActivite: FunctionComponent<CardProps> = ({ localId, onChange, value, activeHint, onDelete }) => {
  const { loading, error, data } = useQuery(GET_ALL_COMPETENCES);
  const [nomActivite, setNomActivite] = useState(value?.name || "");
  const [competenceRating, setCompetenceRating] = useState<{ competenceId: string; rating: number }[]>(
    value?.competences || []
  );
  const { t } = useTranslation();
  const { t: dbt } = useTranslation("database");
  const handleChangeRating = (competenceId: string, newRating: number) => {
    let updateRatings = [...competenceRating];
    let toUpdate = updateRatings.find((v) => v.competenceId === competenceId);
    if (toUpdate) toUpdate.rating = newRating;
    else updateRatings.push({ competenceId, rating: newRating });
    setCompetenceRating(updateRatings);
  };

  useEffect(() => {
    if (data) {
      const defaultRatings = data.getCompetences.map((v: Competence) => {
        return {
          competenceId: v.id,
          rating: competenceRating.find((w) => w.competenceId === v.id)?.rating || 0,
        };
      });
      setCompetenceRating(defaultRatings);
    }
    ReactTooltip.rebuild();
  }, [data]);

  useEffect(() => {
    if (onChange) onChange(nomActivite, competenceRating);
  }, [competenceRating, nomActivite, onChange]);

  const chartData = useMemo(
    () => ({
      datasets: [
        {
          data: competenceRating.map((v) => v.rating),
          backgroundColor: data?.getCompetences?.map((v: Competence) => v.color),
        },
      ],
      labels: data?.getCompetences?.map((v: Competence) => v.name),
    }),
    [competenceRating, data]
  );

  const canSelectNewCompetences = useMemo(() => {
    return competenceRating.filter((v) => v.rating > 0).length < 4;
  }, [competenceRating]);

  const hasRatedCompetence = (id: string) => {
    return competenceRating.filter((v) => v.competenceId === id && v.rating > 0).length > 0;
  };

  return (
    <>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey={localId}>
          <span>
            {activeHint ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />} Activité {localId}
          </span>
          {nomActivite.length > 0 && (
            <>
              <span> : </span>
              <strong className={"text-primary"}>{nomActivite}</strong>
            </>
          )}
          <span
            className={"float-right text-danger"}
            style={{ cursor: "pointer", fontWeight: "bold" }}
            onClick={() => onDelete?.call(undefined)}
          >
            {t("retirer")}
          </span>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={localId}>
          <Card.Body>
            <Form.Group>
              <Form.Label>
                <strong>{t("renseignez_lactivite")}</strong>
              </Form.Label>
              <Form.Control
                type={"text"}
                value={nomActivite}
                onChange={(e) => setNomActivite(e.currentTarget.value)}
                placeholder={t("Tenir une buvette, tenir les comptes d’une association, etc...") }
              />
            </Form.Group>
            <Row>
              <Col xs={12} md={8}>
                <Form.Label>
                  <strong>{t("selectionnez_jusqua_")}</strong>
                </Form.Label>
                {loading && <AppLoader />}
                {error && (
                  <Alert variant={"danger"}>
                    <strong>Erreur lors de la récupération des données</strong>
                    <p>{error.message}</p>
                  </Alert>
                )}
                {data && (
                  <ListGroup variant={"flush"}>
                    {data.getCompetences.map((v: Competence, index: number) => (
                      <ListGroup.Item key={index}>
                        <Row>
                          <Col xs={6} md={8}>
                            <span style={{ wordWrap: "break-word" }}>{dbt(v.name)}</span>
                          </Col>
                          <Col xs={6} md={4}>
                            <div className={"text-right"}>
                              {(canSelectNewCompetences || hasRatedCompetence(v.id)) && (
                                <Rating
                                  color={v.color}
                                  tooltips={v.tooltips?.map(v => dbt(v)) || []}
                                  maxRating={4}
                                  currentRating={competenceRating.find((w) => w.competenceId === v.id)?.rating || 0}
                                  onChange={(newRating) => handleChangeRating(v.id, newRating)}
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </Col>
              <Col xs={12} md={4}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <PolarAreaChart data={chartData} options={chartConfig} />
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export default CarteActivite;
