import React, { FunctionComponent } from "react";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/esm/Button";

interface IProps {
  show: boolean;
  title?: string;
  onConfirm?: () => void;
  confirmText?: string | null;
  onClose?: () => void;
  cancelText?: string | null;
  disableConfirm?: boolean;
  disableClose?: boolean;
}

const AppModal: FunctionComponent<IProps> = ({
  show = false,
  title,
  children,
  onConfirm,
  onClose,
  confirmText = "Confirmer",
  cancelText = "Annuler",
  disableClose = false,
  disableConfirm = false
}) => {
  const handleConfirm = () => {
    if (onConfirm) onConfirm();
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  if (!show) return null;

  return (
    <Modal show={show} onHide={handleClose} centered>
      {title && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {confirmText && (
          <Button variant={"primary"} onClick={handleConfirm} disabled={disableConfirm}>
            {confirmText}
          </Button>
        )}
        {cancelText && (
          <Button variant={"light"} onClick={handleClose} disabled={disableClose}>
            {cancelText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AppModal;
