import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import Chart, { ChartData, ChartOptions } from "chart.js";

type ChartProps = {
  type?: string;
  data: ChartData;
  options: ChartOptions;
};

const PolarAreaChart: FunctionComponent<ChartProps> = ({ type = "polarArea", data, options }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState<Chart>();

  useEffect(() => {
    if (canvasRef.current) {
      if (chart) chart.destroy();
      setChart(
        new Chart(canvasRef.current, {
          type,
          data,
          options,
        })
      );
    }
  }, [data, options]);

  return (
    <div>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default PolarAreaChart;
