import React, { FunctionComponent } from "react";
import { Col } from "react-bootstrap";
import { AiOutlineDelete, BiKey, GoMail } from "react-icons/all";
import { NavLink, Switch } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import DeleteAccount from "./DeleteAccount";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import { Route } from "react-router";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
const Profile: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={"Compte"} />
      <Container>
        <Row>
          <Col md={4}>
            <ListGroup>
              <ListGroup.Item as={NavLink} action to={"/compte/email"}>
                <GoMail />
                <span className={"ml-2"}>{t("changer_mon_e-mail")}</span>
              </ListGroup.Item>
              <ListGroup.Item as={NavLink} action to={"/compte/mot-de-passe"}>
                <BiKey />
                <span className={"ml-2"}>{t("changer_mon_mot_de_pa")}</span>
              </ListGroup.Item>
              <ListGroup.Item as={NavLink} action to={"/compte/supprimer"}>
                <AiOutlineDelete />
                <span className={"ml-2"}>{t("supprimer_mon_compte")}</span>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={8}>
            <Switch>
              <Route exact path={"/compte/email"} component={ChangeEmail} />
              <Route exact path={"/compte/mot-de-passe"} component={ChangePassword} />
              <Route exact path={"/compte/supprimer"} component={DeleteAccount} />
            </Switch>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
