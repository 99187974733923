import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import Chart, { ChartData, ChartOptions } from "chart.js";

type ChartProps = {
  data: ChartData;
  options: ChartOptions;
};

const RadarChart: FunctionComponent<ChartProps> = ({ data, options }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState<Chart>();

  useEffect(() => {
    if (canvasRef.current) {
      if (chart) chart.destroy();
      setChart(
        new Chart(canvasRef.current, {
          type: "radar",
          data,
          options,
        })
      );
    }
  }, [data, options]);

  return (
    <div>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default RadarChart;
