import React, { FunctionComponent } from "react";

type ItemProps = {
  tooltip?: string;
  active?: boolean;
  disabled?: boolean;
  color?: string;
  onClick?: () => void;
};

const RatingItem: FunctionComponent<ItemProps> = ({
  tooltip,
  active = false,
  disabled = false,
  color = "#5156a1",
  ...rest
}) => {
  return (
    <div
      className={"mx-1"}
      style={{
        borderRadius: "50%",
        width: "1.2em",
        height: "1.2em",
        display: "inline-block",
        backgroundColor: active ? color : "transparent",
        border: active ? `2px solid ${color}` : "2px solid #999",
        cursor: disabled ? "initial" : "pointer",
        verticalAlign: "middle",
      }}
      data-tip={tooltip}
      {...rest}
    />
  );
};

type RatingProps = {
  currentRating?: number;
  onChange?: (newValue: number) => void;
  color?: string;
  tooltips?: string[];
  maxRating?: number;
};

const Rating: FunctionComponent<RatingProps> = ({
  currentRating = 0,
  onChange,
  color,
  tooltips = [],
  maxRating = 5,
}) => {
  const handleClickStar = (index: number) => {
    const newValue = currentRating === index ? 0 : index;
    if (onChange) onChange(newValue);
  };

  return (
    <div>
      {[...Array(maxRating)].map((_, i) => (
        <RatingItem
          key={i}
          tooltip={tooltips.length >= i + 1 ? tooltips[i] : undefined}
          active={i + 1 <= currentRating}
          onClick={() => handleClickStar(i + 1)}
          color={color}
        />
      ))}
    </div>
  );
};

export default Rating;
