import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Card from "react-bootstrap/esm/Card";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import Col from "react-bootstrap/esm/Col";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_COMPETENCE_FOR_ADMIN, GET_ALL_COMPETENCES_FOR_ADMIN,
  GET_COMPETENCE_FOR_ADMIN,
  UPDATE_COMPETENCE_FOR_ADMIN
} from "../../../requests/Competence";
import AppLoader from "../../../components/AppLoader";
import Alert from "react-bootstrap/esm/Alert";

const EditCompetence = () => {
  const history = useHistory();
  const { id: competenceId } = useParams<any>();
  const [backendError, setBackendError] = useState<any>(null);
  const [title, setTitle] = useState<string>("");
  const [color, setColor] = useState<string>("#000000");
  const [tooltips, setTooltips] = useState<string[]>(["", "", "", ""]);
  const [callFetch, { loading, error }] = useLazyQuery(GET_COMPETENCE_FOR_ADMIN, {
    onCompleted: (data) => {
      const competence = data.getCompetenceById;
      setTitle(competence.name);
      setColor(competence.color);
      setTooltips(competence.tooltips);
    },
  });
  const [updateCompetence] = useMutation(UPDATE_COMPETENCE_FOR_ADMIN, {
    refetchQueries: [ { query: GET_ALL_COMPETENCES_FOR_ADMIN }],
    onCompleted: () => history.push("/admin/competences"),
    onError: (error1) => setBackendError(error1.message),
  });
  const [createCompetence] = useMutation(CREATE_COMPETENCE_FOR_ADMIN, {
    refetchQueries: [ { query: GET_ALL_COMPETENCES_FOR_ADMIN }],
    onCompleted: () => history.push("/admin/competences"),
    onError: (error1) => setBackendError(error1.message),
  });

  useEffect(() => {
    if (competenceId)
      callFetch({
        variables: {
          id: competenceId,
        },
      });
  }, [competenceId, callFetch]);

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.currentTarget.value);
  };

  const handleChangeColor = (e: ChangeEvent<HTMLInputElement>) => {
    setColor(e.currentTarget.value);
  };

  const handleEditTooltip = (index: number, e: ChangeEvent<HTMLInputElement>) => {
    const update = [...tooltips];
    update[index] = e.currentTarget.value;
    setTooltips(update);
  };

  const handleFormSubmit = () => {
    if (competenceId) {
      updateCompetence({
        variables: {
          competenceId,
          updateData: {
            name: title,
            color,
            tooltips,
          },
        },
      });
    } else {
      createCompetence({
        variables: {
          creationData: {
            name: title,
            color,
            tooltips,
          },
        },
      });
    }
  };

  return (
    <Card>
      <Card.Header>
        <strong>{competenceId ? "Edition d'une compétence" : "Créer une compétence"}</strong>
      </Card.Header>
      {competenceId && loading ? (
        <AppLoader />
      ) : (
        <Card.Body>
          {backendError && (
            <Alert variant={"danger"}>
              <strong>Erreur lors de la mise à jour</strong>
              <div>{backendError}</div>
            </Alert>
          )}
          <Form>
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label className={"form-required"}>Nom de la compétence</Form.Label>
                  <Form.Control type="text" required onChange={handleChangeTitle} value={title} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className={"form-required"}>Couleur</Form.Label>
                  <Form.Control type="color" required onChange={handleChangeColor} value={color} />
                </Form.Group>
              </Col>
            </Form.Row>
            {[1, 2, 3, 4].map((v, index) => (
              <Form.Group>
                <Form.Label>Description du palier {v}</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleEditTooltip(index, e)}
                  value={tooltips[index] || ""}
                />
              </Form.Group>
            ))}
          </Form>
          <Button onClick={handleFormSubmit}>Valider</Button>
        </Card.Body>
      )}
    </Card>
  );
};

export default EditCompetence;
