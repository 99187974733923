import React, { useState, useEffect, useContext, useMemo } from "react";
import CarteActivite from "../../../components/CarteActivite";
import ReactTooltip from "react-tooltip";
import { NewParcoursContext } from "../../../contexts/NewParcoursContext";
import { Activite } from "../../../requests/Activite";
import { Alert, Button, Accordion, Container, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ChoixCompetences = () => {
  const { t } = useTranslation();
  const context = useContext(NewParcoursContext);
  const [activeKey, setActiveKey] = useState<string>();
  const [activites, setActivites] = useState<Activite[]>([{ localReactId: Math.random(), name: "", competences: [] }]);

  useEffect(() => {
    setActivites([...context.activites]);
  }, []);

  const handleAddActivite = () => {
    setActivites([...activites, { localReactId: Math.random(), name: "", competences: [] }]);
  };

  const handleNextStep = () => {
    context.setActivites([...activites]);
    context.goToNextStep();
  };

  const handleActiviteUpdate = (idActivite: number, nomActivite: string, ratings: any) => {
    let update = [...activites];
    let elem = update[idActivite];

    if (elem.name !== nomActivite || elem.competences !== ratings) {
      update[idActivite].name = nomActivite;
      update[idActivite].competences = ratings;
      setActivites([...update]);
    }
  };

  const isValidForNextStep = useMemo(() => {
    return (
      activites.length > 0 &&
      activites.every((v) => {
        const nonZeroRating = v.competences.filter((w) => w.rating > 0);
        return v.name && v.name.length > 0 && nonZeroRating.length >= 1 && nonZeroRating.length <= 4;
      })
    );
  }, [activites]);

  // Expands the accordion card of the new Competence
  useEffect(() => {
    setActiveKey(activites.length.toString());
  }, [activites.length]);

  const handleDeleteActivite = (ai: number) => {
    console.log(activites, "ai:", ai);
    const r = activites.filter((v, index) => index !== ai);
    console.log("r:", r);
    setActivites([...r]);
  };

  return (
    <>
      <Container>
        <Card>
          <Card.Header className={"bg-primary text-light"}>
            {t("etape_33__identifie")}
          </Card.Header>
          <Card.Body>
            <Alert variant={"info"}>
              <Alert.Heading>{t("decoupez_votre_experi")}</Alert.Heading>
              <div>
                {t("exemple__organiser_u")}
                <ul>
                  <li>{t("activite_1__chercher")}</li>
                  <li>{t("activite_2__comparer")}</li>
                  <li>{t("activite_3__organise")}</li>
                  <li>{t("activite_4__preparer")}</li>
                </ul>
              </div>
            </Alert>
            <div>
              <Accordion activeKey={activeKey} onSelect={(e: any) => setActiveKey(e)}>
                {activites.map((v, index) => (
                  <CarteActivite
                    key={v.localReactId}
                    value={activites[index]}
                    localId={(index + 1).toString()}
                    onChange={(...args) => handleActiviteUpdate(index, ...args)}
                    activeHint={(activeKey || 0) == index + 1}
                    onDelete={() => handleDeleteActivite(index)}
                  />
                ))}
              </Accordion>
              <div className={"mt-2 text-right"}>
                <Button variant={"success"} onClick={handleAddActivite}>
                  {t("ajouter_une_activite")}
                </Button>
              </div>
            </div>
            <div className={"mt-2 text-center"}>
              <Button variant={"secondary"} onClick={context.goToPrevStep}>
                {t("retour")}
              </Button>
              &nbsp;
              <Button variant={"primary"} disabled={!isValidForNextStep} onClick={handleNextStep}>
                {t("etape_suivante")}
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Container>
      <ReactTooltip />
    </>
  );
};

export default ChoixCompetences;
