import React, { useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { UserContext } from "../../contexts/UserContext";
import AppLoader from "../AppLoader";

export interface IProps extends RouteProps {
  isProtected?: boolean;
  isAdminOnly?: boolean;
}

const AppRoute = ({ isProtected, isAdminOnly, ...rest }: IProps) => {
  const { user } = useContext(UserContext);
  if (user === undefined) return <AppLoader />;
  if (user === null && isProtected) return <Redirect to={"/connexion"} />;
  if ((!user || user.role !== "admin") && isAdminOnly) return <Redirect to={"/"} />;

  return <Route {...rest} />;
};

export default AppRoute;
