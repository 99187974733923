import React from "react";
import { RouteComponentProps } from "react-router";
import { Route, Switch } from "react-router-dom";

export interface IProps {
  path: string;
  listComponent?: React.ComponentType;
  editComponent?: React.ComponentType;
  viewComponent?: React.ComponentType;
}

const AppCRUDRoute = ({ path, listComponent, editComponent, viewComponent }: IProps) => {
  return (
    <Route
      path={path}
      component={({ match }: RouteComponentProps) => (
        <Switch>
          {editComponent && <Route exact path={`${match.path}/new`} component={editComponent} />}
          {editComponent && <Route exact path={`${match.path}/:id/edit`} component={editComponent} />}
          {viewComponent && <Route exact path={`${match.path}/:id`} component={viewComponent} />}
          {listComponent && <Route path={`${match.path}/`} component={listComponent} />}
        </Switch>
      )}
    />
  );
};

export default AppCRUDRoute;
