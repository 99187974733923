import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Parcours from "./views/Parcours";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import { User, UserContext } from "./contexts/UserContext";
import Login from "./views/Login";
import Register from "./views/Register";
import { useLazyQuery } from "@apollo/client";
import { REFRESH_TOKEN } from "./requests/User";
import AppRoute from "./components/AppRoute";
import Home from "./views/Home";
import { Helmet } from "react-helmet";
import AdminPanel from "./views/AdminPanel";
import CarteCompetence from "./views/CarteCompetence";
import GameEmbed from "./views/GameEmbed";
import MyExperiencesRenewal from "./views/MyExperiencesRenewal";
import Account from "./views/Account";
import CarteCompetenceGlobale from "./views/CarteCompetenceGlobale";
import { Card, Container } from "react-bootstrap";

const App = () => {
  const [user, setUser] = useState<User | undefined | null>(undefined);
  const [refreshToken] = useLazyQuery(REFRESH_TOKEN, {
    onCompleted: (data) => {
      if (data && data.refreshToken) {
        setUser(data.refreshToken.user);
        localStorage.setItem("auth_token", data.refreshToken.token);
      } else {
        setUser(null);
        localStorage.removeItem("auth_token");
      }
    },
  });

  useEffect(() => {
    if (refreshToken) {
      if (localStorage.getItem("auth_token")?.length) refreshToken();
      else setUser(null);
    }
  }, [refreshToken]);

  return (
    <Suspense fallback={"Loading ..."}>
      <UserContext.Provider value={{ user, setUser }}>
        <Helmet titleTemplate={"%s • REQUAPASS"} defaultTitle={"REQUAPASS"} />
        <Router>
          <Switch>
            <Route exact path={"/carte/"} component={CarteCompetenceGlobale} />
            <Route exact path={"/carte/experience/:experienceId"} component={CarteCompetence} />
            <Route path={"/"}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100vh",
                }}
              >
                <AppHeader />
                <main style={{ flex: 1 }}>
                  <Switch>
                    <AppRoute isProtected={true} path={"/experiences"} component={MyExperiencesRenewal} />
                    <AppRoute isProtected={true} path={"/parcours/:parcoursDbId?"} component={Parcours} />
                    <AppRoute exact path={"/connexion"} component={Login} />
                    <AppRoute exact path={"/inscription"} component={Register} />
                    <AppRoute exact path={"/login"} render={() => <Redirect to={"/connexion"} />} />
                    <AppRoute exact path={"/register"} component={() => <Redirect to={"/inscription"} />} />
                    <AppRoute isProtected={true} exact path={"/"} component={Home} />
                    <AppRoute isProtected={true} path={"/admin"} component={AdminPanel} />
                    <AppRoute isProtected={true} path={"/compte"} component={Account} />
                    <AppRoute isProtected={true} exact path={"/jeu"} component={GameEmbed} />
                    <AppRoute render={() => <h1>Not Found</h1>} />
                  </Switch>
                </main>
                <Container className={"mb-1"}>
                  <Card body>
                    <AppFooter />
                  </Card>
                </Container>
              </div>
            </Route>
          </Switch>
        </Router>
      </UserContext.Provider>
    </Suspense>
  );
};

export default App;
