import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import "./config/i18next";
import { ToastProvider } from "react-toast-notifications";
import { onError } from "@apollo/client/link/error";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URI,
});

const applyAuthorizationHeader = setContext((request, previousContext) => ({
  headers: {
    ...previousContext.headers,
    authorization: localStorage.getItem("auth_token") || undefined,
  },
}));

const errorLink = onError(({ networkError, graphQLErrors }) => {
  graphQLErrors && console.log("⚛ GraphQL Error", graphQLErrors);
  networkError && console.log("👮🏻 Network Error", networkError);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: applyAuthorizationHeader.concat(errorLink).concat(httpLink),
});

ReactDOM.render(
  <ToastProvider autoDismiss={true} autoDismissTimeout={8000} placement={"top-center"}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </ToastProvider>,
  document.getElementById("root")
);
