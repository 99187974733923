import React, { FunctionComponent, useMemo } from "react";
import chartConfig from "../../config/chartBilan";
import PolarAreaChart from "../../components/PolarAreaChart";
import { useQuery } from "@apollo/client";
import { GET_ALL_COMPETENCES, GetAllCompetencesResponse } from "../../requests/Competence";
import { ChartColor, ChartData } from "chart.js";
import AppLoader from "../../components/AppLoader";
import { GET_ALL_EXP_SUMMARY } from "../../requests/Parcours";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GlobalExp: FunctionComponent = () => {
  const { t } = useTranslation();
  const { t: dbt } = useTranslation("database");
  const { data: { getCompetences: competences } = {}, loading } = useQuery<GetAllCompetencesResponse>(
    GET_ALL_COMPETENCES
  );
  const { data: { getAllExperiencesSummary: summary } = {}, loading: loadingExp } = useQuery(GET_ALL_EXP_SUMMARY, {
    fetchPolicy: "network-only",
  });

  const chartData = useMemo((): ChartData => {
    if (!competences || !summary) return {};
    return {
      datasets: [
        {
          data: competences.map((v) => summary.find((w: any) => w.competenceId === v.id)?.rating || 0),
          backgroundColor: competences.map((v) => v.color as ChartColor),
        },
      ],
      labels: competences.map((v) => dbt(v.name) as string[]),
    };
  }, [competences, summary]);

  if (loading || loadingExp) return <AppLoader />;

  return (
    <div>
      <div className={"pr-1"}>
        <PolarAreaChart
          data={chartData}
          options={{
            ...chartConfig,
            ...{ legend: { position: "left" } },
          }}
        />
      </div>
      <Link to={`/carte`} target={"_blank"}>
        <Button className={"ml-2"} variant={"primary"}>
          {t("telecharger")} / {t("imprimer")}
        </Button>
      </Link>
    </div>
  );
};

export default GlobalExp;
;
