import React, { FunctionComponent, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Card } from "react-bootstrap";
import PolarAreaChart from "../../../components/PolarAreaChart";
import { gql, useQuery } from "@apollo/client";
import { ChartColor } from "chart.js";
import { GET_ALL_CRITERES_AND_DOMAINES } from "../../../requests/Critere";
import { useTranslation } from "react-i18next";

const Stats: FunctionComponent = () => {
  const { t: dbt } = useTranslation("database");
  const { data: { domaineStatistics, getDomaines: domaines } = {} } = useQuery(
    gql`
      query {
        domaineStatistics {
          name
          count
        }
        getDomaines {
          name
        }
      }
    `,
    { fetchPolicy: "network-only" }
  );

  const chartData = useMemo(() => {
    if (!domaineStatistics) return {};
    return {
      datasets: [
        {
          data: domaines.map((v: any) => domaineStatistics.find((w: any) => w.name === v.name)?.count || 0),
          backgroundColor: [
            "#736DC2",
            "#4A76C6",
            "#FF301A",
            "#EB0165",
            "#DB44B0",
            "#B43790",
            "#21D1D9",
            "#41E1C6",
            "#45BFDE",
            "#32A16B",
            "#60D06D",
          ],
        },
      ],
      labels: domaines.map((v: any) => dbt(v.name)),
    };
  }, [domaineStatistics]);

  return (
    <>
      <Helmet title={"Statistiques"} />
      <Card body>
        <PolarAreaChart
          type={"doughnut"}
          data={chartData}
          options={{
            title: { display: true, text: "Répartition des domaines par expérience", fontSize: 18 },
            legend: { position: "right" },
          }}
        />
      </Card>
    </>
  );
};

export default Stats;
