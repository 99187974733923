import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import burgerspeedThumbnail from "../../assets/jeu_burgerspeed.png";
import skillcardThumbnail from "../../assets/carte.png";
import { Button, Col, Row, Image, Card, Container } from "react-bootstrap";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import AppModal from "../../components/AppModal";
import { useMutation } from "@apollo/client";
import { UPDATE_TUTORIAL_STATE } from "../../requests/User";
import { UserContext } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { user, setUser } = useContext(UserContext);
  const [tutoStep, setTutoStep] = useState(
    user?.tutorialComplete ? 99 : Number.parseInt(localStorage.getItem("tutorial") || "0", 10)
  );
  const [commitTutorialComplete] = useMutation(UPDATE_TUTORIAL_STATE, {
    onCompleted: () => {
      if (user) setUser({ ...user, tutorialComplete: true });
    },
  });
  const history = useHistory();
  const location = useLocation();

  const handleTuto1Confirm = () => {
    setTutoStep(1);
  };

  const handleTuto2Confirm = () => {
    history.push("/jeu");
  };

  const handleTuto2Cancel = () => {
    setTutoStep(2);
    commitTutorialComplete();
    localStorage.setItem("tutorial", "2");
  };

  const handleTuto3Confirm = () => {
    setTutoStep(99);
    localStorage.setItem("tutorial", "99");
    history.push("/parcours");
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const gameOver = urlParams.get("game_over") === "1";

    if (gameOver && !user?.tutorialComplete) {
      setTutoStep(2);
      commitTutorialComplete();
      localStorage.setItem("tutorial", "2");
    }
  }, [location.search, tutoStep]);

  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t("accueil")} />
      <Container>
        <h2>{t("home.welcome")}</h2>
        <Row>
          <Col md={6}>
            <Card>
              <Card.Header>{t("home.apprendre_idd_val_comp")}</Card.Header>
              <Card.Body>
                <Image className={"float-left"} src={burgerspeedThumbnail} height={128} />
                <div className={"text-right"}>
                  <div>
                    <h4 className={"mb-0"}>Burger Speed</h4>
                    <small>{t("home.game_simu_time")}</small>
                  </div>
                  <p>
                    {t("home.description")}
                  </p>
                  <div>
                    <Button as={NavLink} to={"/jeu"} variant={"dark"}>
                      {t("jouer")}
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <Card.Header>{t("construire_ma_carte_d")}</Card.Header>
              <div className={"text-center"}>
                <Image className={"my-2"} src={skillcardThumbnail} height={196} />
                <Link to={"/parcours"}>
                  <Button block variant={"primary"} className={"mt-2"}>
                    {t("commencer")}
                  </Button>
                </Link>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      {tutoStep === 0 && (
        <AppModal show={true} cancelText={null} confirmText={"Suivant"} onConfirm={handleTuto1Confirm}>
          Bienvenue sur l’application de reconnaissances des compétences transversales des bénévoles REQUAPASS. Cette
          application va vous aider à amorcer un travail réflexif sur vos compétences en situation de bénévolat.
        </AppModal>
      )}
      {tutoStep === 1 && (
        <AppModal
          show={true}
          cancelText={"Passer le jeu"}
          confirmText={"Lancer le jeu"}
          onConfirm={handleTuto2Confirm}
          onClose={handleTuto2Cancel}
        >
          Un mini jeu va vous aider à prendre en main l’application et vous formez à la méthodologie d’identification et
          de reconnaissance des compétences transversales.
        </AppModal>
      )}
      {tutoStep === 2 && (
        <AppModal show={true} cancelText={null} confirmText={t("commencer") + " ma carte"} onConfirm={handleTuto3Confirm}>
          Vous pouvez dès à présent vous lancer dans la construction de votre carte !
        </AppModal>
      )}
    </>
  );
};

export default Home;
