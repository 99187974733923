import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import locales from "./locales";

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "fr-FR",
    supportedLngs: [...locales.map((l) => l.lng)],
    load: "currentOnly",
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for React as it escapes by default
    },
  });

export default i18next;
