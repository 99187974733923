import React, { useEffect, useState } from "react";
import ChoixTheme from "./ChoixTheme";
import ChoixCriteres from "./ChoixCriteres";
import ChoixCompetences from "./ChoixCompetences";
import Bilan from "./Bilan";
import { EParcoursStep, NewParcoursContext } from "../../contexts/NewParcoursContext";
import { Activite } from "../../requests/Activite";
import { useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import { GET_EXPERIENCE_BY_ID } from "../../requests/Parcours";

const Parcours = () => {
  const [step, setStep] = useState<EParcoursStep>(EParcoursStep.THEME);
  const [themeName, setThemeName] = useState<string>("");
  const [criteres, setCriteres] = useState<{ critereId: string; rating: number }[]>([]);
  const [activites, setActivites] = useState<Activite[]>([{ localReactId: Math.random(), name: "", competences: [] }]);
  const [domaine, setDomaine] = useState<string | null>(null);
  const [runFetch, { data }] = useLazyQuery(GET_EXPERIENCE_BY_ID);
  const { parcoursDbId: experienceId } = useParams<any>();

  const contextValue = {
    themeName,
    ratedCriteres: criteres,
    activites,
    domaine,
    setThemeName,
    setRatedCriteres: setCriteres,
    setActivites,
    setDomaine,
    goToNextStep: () => setStep(step + 1),
    goToPrevStep: () => setStep(step - 1),
    resetNewParcours: () => {
      setStep(EParcoursStep.THEME);
      setThemeName("");
      setActivites([{ localReactId: Math.random(), name: "", competences: [] }]);
      setCriteres([]);
      setDomaine(null);
    },
  };

  useEffect(() => {
    if (experienceId) {
      runFetch({
        variables: {
          experienceId: experienceId,
        },
      });
    } else {
      contextValue.resetNewParcours();
    }
  }, [experienceId, runFetch]);

  useEffect(() => {
    if (experienceId && data && data.getExperienceById) {
      setThemeName(data.getExperienceById.name);
      setCriteres(
        data.getExperienceById.criteres.map((v: any) => ({
          critereId: v.critereId,
          rating: v.rating,
        }))
      );
      setActivites(
        data.getExperienceById.activites.map((v: any) => ({
          localReactId: Math.random(),
          name: v.name,
          competences: v.competences.map((w: any) => ({
            competenceId: w.competenceId,
            rating: w.rating,
          })),
        }))
      );
      console.log(data.getExperienceById.domaine);
      setDomaine(data.getExperienceById.domaine || null);
    }
  }, [data, experienceId]);

  return (
    <NewParcoursContext.Provider value={contextValue}>
      {step === EParcoursStep.THEME && <ChoixTheme />}
      {step === EParcoursStep.CRITERES && <ChoixCriteres />}
      {step === EParcoursStep.COMPETENCES && <ChoixCompetences />}
      {step === EParcoursStep.BILAN && <Bilan />}
    </NewParcoursContext.Provider>
  );
};

export default Parcours;
