import React, { useContext, useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import PolarAreaChart from "../../../components/PolarAreaChart";
import chartConfig from "../../../config/chartBilan";
import { NewParcoursContext } from "../../../contexts/NewParcoursContext";
import { useMutation, useQuery } from "@apollo/client";
import { APPEND_PARCOURS_MUTATION, UPDATE_PARCOURS_EXP_MUTATION } from "../../../requests/Parcours";
import { Helmet } from "react-helmet";
import { Competence, GET_ALL_COMPETENCES } from "../../../requests/Competence";
import AppLoader from "../../../components/AppLoader";
import { useParams } from "react-router";
import { FaPrint } from "react-icons/all";
import Button from "react-bootstrap/esm/Button";
import { useTranslation } from "react-i18next";

const Bilan = () => {
  const { t } = useTranslation();
  const { t: dbt } = useTranslation("database");
  const params: any = useParams();
  const context = useContext(NewParcoursContext);
  const [backendExpId, setBackendExpId] = useState<string | null>(null);
  const { data: competenceList, loading: competencesLoading } = useQuery(GET_ALL_COMPETENCES);
  const [addExperienceToParcours] = useMutation(APPEND_PARCOURS_MUTATION, {
    onCompleted: (data1) => {
      setBackendExpId(data1.appendParcours.id);
    },
  });
  const [editExperienceInParcours] = useMutation(UPDATE_PARCOURS_EXP_MUTATION, {
    onCompleted: (data1) => {
      setBackendExpId(data1.updateParcoursExperience.id);
    },
  });

  const chartData = useMemo(() => {
    const res: any = {};
    for (let a of context.activites) {
      for (let b of a.competences) {
        if (res[b.competenceId] === undefined) {
          const compDbData = competenceList.getCompetences.find((v: Competence) => v.id === b.competenceId);
          res[b.competenceId] = {
            name: compDbData?.name || "Nom invalide",
            color: compDbData?.color || undefined,
            rating: b.rating,
          };
        } else res[b.competenceId].rating = Math.max(res[b.competenceId].rating, b.rating);
      }
    }

    return Object.values(res);
  }, [competenceList.getCompetences, context.activites]);

  const data = useMemo(
    () => ({
      datasets: [
        {
          data: chartData.map((v: any) => v.rating),
          backgroundColor: chartData.map((v: any) => v.color),
        },
      ],
      labels: chartData.map((v: any) => dbt(v.name)),
    }),
    [chartData]
  );

  useEffect(() => {
    if (params.parcoursDbId) {
      editExperienceInParcours({
        variables: {
          experienceId: params.parcoursDbId,
          updateData: {
            name: context.themeName,
            domaine: context.domaine,
            criteres: context.ratedCriteres,
            activites: context.activites,
          },
        },
      });
    } else {
      addExperienceToParcours({
        variables: {
          updateData: {
            name: context.themeName,
            domaine: context.domaine,
            criteres: context.ratedCriteres,
            activites: context.activites,
          },
        },
      });
    }
  }, [
    addExperienceToParcours,
    editExperienceInParcours,
    params.parcoursDbId,
    context.themeName,
    context.activites,
    context.ratedCriteres,
    context.domaine,
  ]);

  if (competencesLoading) return <AppLoader />;

  return (
    <>
      <Helmet title={"Bilan"} />
      <Container>
        <h2>{t("bilan_de_votre_experi")}</h2>
        <Card body>
          <div className={"mb-3"}>
            <PolarAreaChart data={data} options={chartConfig} />
          </div>
          <Button href={`/carte/experience/${backendExpId}`}>
            <FaPrint />
            &nbsp;{t("imprimer")} / {t("telecharger")}
          </Button>
        </Card>
      </Container>
    </>
  );
};

export default Bilan;
