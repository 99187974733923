import React, { FunctionComponent } from "react";
import { Card } from "react-bootstrap";

interface IProps {
  color: string;
  title: string;
  description: string;
  rating: number;
}

const MiniCompetenceCard: FunctionComponent<IProps> = ({ title, description, color, rating }) => {
  return (
    <Card body className={"mini-competence-card my-1 w-100"} style={{ borderLeft: `8px solid ${color}`, padding: 0 }}>
      <div className={"text-left ml-3"}>
        <strong>{title}</strong>
        <div>
          <strong style={{ color: color }}>Niveau {rating}</strong>
        </div>
        <div>{description}</div>
      </div>
    </Card>
  );
};

export default MiniCompetenceCard;
