import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import { UserContext } from "../../contexts/UserContext";
import AppLanguageMenu from "../AppLanguageMenu";
import { useTranslation } from "react-i18next";
const AppHeader = () => {
  const { user, setUser } = useContext(UserContext);
  const { t } = useTranslation();
  if (!user) return <></>;

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem("auth_token");
  };

  return (
    <header>
      <Navbar bg={"light"} variant={"light"} style={{ borderBottom: "2px solid #5156a1" }}>
        <Container>
          <Nav className={"mr-auto"}>
            <Nav.Link as={NavLink} to={"/"} exact>
              Accueil
            </Nav.Link>
            {user && (
              <>
                <Nav.Link as={NavLink} to={"/parcours"}>
                  {t("ajouter_une_experienc")}
                </Nav.Link>
                <Nav.Link as={NavLink} exact to={"/experiences"}>
                  {t("mes_experiences")}
                </Nav.Link>
                {user && user.role === "admin" && (
                  <Nav.Link as={NavLink} to={"/admin"}>
                    Administration
                  </Nav.Link>
                )}
              </>
            )}
          </Nav>
          <Nav>
            {user && (
              <NavDropdown title={<>{t("connecte")} : {user.email}</>} id={"user-menu-dropdown"}>
                <NavDropdown.Item exact as={NavLink} to={"/compte"}>
                  {t("parametres_du_compte")}
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handleLogout}>{t("deconnexion")}</NavDropdown.Item>
              </NavDropdown>
            )}
            {!user && (
              <Nav.Link as={NavLink} exact to="/connexion">
                Se connecter
              </Nav.Link>
            )}
            <AppLanguageMenu />
          </Nav>
        </Container>
      </Navbar>
    </header>
  );
};

export default AppHeader;






