import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ListGroup, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AppCRUDRoute from "../../components/AppCRUDRoute";
import ListUsers from "./User/ListUsers";
import ListCompetences from "./Competence/ListCompetences";
import ListCriteres from "./Critere/ListCriteres";
import EditCompetence from "./Competence/EditCompetence";
import EditCritere from "./Critere/EditCritere";
import { BsListTask, BiAtom, FaUser } from "react-icons/all";
import { UserContext } from "../../contexts/UserContext";
import { Redirect } from "react-router";
import Stats from "./Stats";

const AdminPanel = () => {
  const { user } = useContext(UserContext);

  if (!user || user.role !== "admin") return <Redirect to={"/"} />;

  return (
    <>
      <Helmet title={"Panneau d'administration"} />
      <Container>
        <h2>Panneau d'administration</h2>
        <ListGroup horizontal>
          <ListGroup.Item as={NavLink} to={"/admin/criteres"} action>
            Critères
          </ListGroup.Item>
          <ListGroup.Item as={NavLink} to={"/admin/competences"} action>
            Compétences
          </ListGroup.Item>
          <ListGroup.Item as={NavLink} to={"/admin/users"} action>
            Utilisateurs
          </ListGroup.Item>
          <ListGroup.Item as={NavLink} to={"/admin/statistiques"} action>
            Statistiques
          </ListGroup.Item>
        </ListGroup>
        <div className={"mt-2"}>
          <AppCRUDRoute path={"/admin/criteres"} listComponent={ListCriteres} editComponent={EditCritere} />
          <AppCRUDRoute path={"/admin/competences"} listComponent={ListCompetences} editComponent={EditCompetence} />
          <AppCRUDRoute path={"/admin/users"} listComponent={ListUsers} />
          <AppCRUDRoute path={"/admin/statistiques"} listComponent={Stats} />
        </div>
      </Container>
    </>
  );
};

export default AdminPanel;
