import { gql } from "@apollo/client";

export type Critere = {
  id: string;
  name: string;
  description?: string;
  help?: string;
  tooltips?: string[];
};

export const GET_ALL_CRITERES = gql`
  {
    getCriteres {
      id
      name
      description
      help
      tooltips
    }
  }
`;

export const GET_ALL_CRITERES_AND_DOMAINES = gql`
  {
    getCriteres {
      id
      name
      description
      help
      tooltips
    }

    getDomaines {
      id
      name
    }
  }
`;

export const GET_ALL_CRITERES_FOR_ADMIN = gql`
  {
    getCriteres {
      id
      name
    }
  }
`;

export const GET_CRITERE_FOR_ADMIN = gql`
  query($id: ID!) {
    getCritereById(id: $id) {
      id
      name
      description
      help
      tooltips
    }
  }
`;

export const UPDATE_CRITERE_FOR_ADMIN = gql`
  mutation($critereId: ID!, $updateData: CreateCritereArgs!) {
    editCritere(critereId: $critereId, updateData: $updateData) {
      id
      name
      description
      help
      tooltips
    }
  }
`;

export const CREATE_CRITERE_FOR_ADMIN = gql`
  mutation($creationData: CreateCritereArgs!) {
    newCritere(creationData: $creationData) {
      id
      name
      description
      help
      tooltips
    }
  }
`;

export const DELETE_CRITERE_FOR_ADMIN = gql`
  mutation($id: ID!) {
    deleteCritere(id: $id)
  }
`;
