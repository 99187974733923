import React, { FunctionComponent } from "react";
import Card from "react-bootstrap/Card";
import { FaQuestionCircle } from "react-icons/fa";
import Rating from "../Rating";
import ListGroup from "react-bootstrap/ListGroup";
import { useTranslation } from "react-i18next";

type CardProps = {
  name: string;
  description?: string;
  help?: string;
  tooltips?: string[];
  rating: number;
  onChangeRating: (newRating: number) => void;
};

const CarteCritere: FunctionComponent<CardProps> = ({ name, description, help, tooltips, onChangeRating, rating }) => {
  const { t: dbt } = useTranslation("database");

  return (
    <ListGroup.Item className={"my-1"}>
      <div className={"float-left"}>
        <Card.Title>
          <span>{dbt(name)}&nbsp;</span>
          {help && <FaQuestionCircle data-tip={dbt(help)} />}
        </Card.Title>
        {description && <Card.Subtitle className="text-muted">{dbt(description)}</Card.Subtitle>}
      </div>
      <div className={"float-right text-center"}>
        <Rating tooltips={tooltips?.map(v => dbt(v)) || []} maxRating={4} currentRating={rating} onChange={onChangeRating} />
      </div>
    </ListGroup.Item>
  );
};

export default CarteCritere;
