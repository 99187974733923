import React, { FormEvent, useContext, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import { useLazyQuery } from "@apollo/client";
import { LOGIN_QUERY } from "../../requests/User";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { Redirect, useHistory, useLocation } from "react-router";
import Alert from "react-bootstrap/Alert";
import { UserContext } from "../../contexts/UserContext";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { register, handleSubmit, errors } = useForm();

  const [runLogin, { loading }] = useLazyQuery(LOGIN_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.login && data.login.token) {
        localStorage.setItem("auth_token", data.login.token);
        setUser(data.login.user);
        history.push("/");
      } else {
        localStorage.removeItem("auth_token");
        setError("Connexion échouée. Vérifiez vos identifiants.");
      }
    },
  });

  const handleLoginSubmit = (data: any) => {
    runLogin({
      variables: { email: data.email, password: data.password },
    });
  };

  const registrationSucceed = useMemo(() => {
    return new URLSearchParams(location.search).get("registration_succeed") === "1";
  }, [location.search]);

  if (user) return <Redirect to={"/"} />;

  return (
    <>
      <Helmet title={"Connexion"} />
      {!user && (
        <Container>
          <h2>Connexion</h2>
          <Card body>
            {registrationSucceed && (
              <Alert variant={"success"}>
                L'inscription s'est effectuée avec succès, vous pouvez désormais vous connecter.
              </Alert>
            )}
            {error && <Alert variant={"danger"}>{error}</Alert>}
            <Form onSubmit={handleSubmit(handleLoginSubmit)}>
              <Form.Group>
                <Form.Label>{t("adresse_e-mail")} :</Form.Label>
                <Form.Control
                  ref={register({ required: { value: true, message: "Ce champ est requis" } })}
                  name="email"
                  type={"email"}
                  isInvalid={!!errors.email}
                />
                {errors.email && <Form.Control.Feedback type={"invalid"}>{errors.email.message}</Form.Control.Feedback>}
              </Form.Group>
              <Form.Group>
                <Form.Label>Mot de passe :</Form.Label>
                <Form.Control
                  ref={register({ required: { value: true, message: "Ce champ est requis" } })}
                  name="password"
                  type={"password"}
                  isInvalid={!!errors.password}
                />
                {errors.password && (
                  <Form.Control.Feedback type={"invalid"}>{errors.password.message}</Form.Control.Feedback>
                )}
              </Form.Group>
              <Button type={"submit"} disabled={loading}>
                Se connecter
              </Button>
            </Form>
          </Card>
          <Card.Footer className={"mt-2"}>
            Pas encore de compte ?{" "}
            <Link to="/inscription">
              <strong>Cliquez ici</strong>
            </Link>{" "}
            pour vous enregistrer
          </Card.Footer>
        </Container>
      )}
    </>
  );
};

export default Login;
