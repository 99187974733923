import React, { useState } from "react";
import { Alert, Button, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Unity, { UnityContent } from "react-unity-webgl";
import AppLoader from "../../components/AppLoader";
import { useTranslation } from "react-i18next";

const unityContent = new UnityContent("cookingfever-build/CFE.json", "cookingfever-build/UnityLoader.js", {
  adjustOnWindowResize: true,
});

const GameEmbed = () => {
  const [loaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();
  unityContent.on("progress", (progress: number) => {
    setProgress(progress);
  });

  unityContent.on("loaded", () => {
    setLoaded(true);
  });

  return (
    <div className={"text-center"}>
      <Alert variant={"info"}>
        <Alert.Heading>Une fois que vous avez complété le jeu ...</Alert.Heading>
        <a href="/?game_over=1" className="btn btn-primary btn-lg">
          {t("cliquez_ici_pour_reve")}
        </a>
      </Alert>
      <Container fluid>
        {!loaded && (
          <div>
            <AppLoader />
            <p>Chargement du jeu : {+Number(progress * 100).toFixed(2)} %. Veuillez patienter.</p>
          </div>
        )}
        <div style={{ height: 720, width: 1280, margin: "auto", visibility: loaded ? "visible" : "hidden" }}>
          <Unity unityContent={unityContent} />
        </div>
      </Container>
    </div>
  );
};

export default GameEmbed;
